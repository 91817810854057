// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-block-js": () => import("../src/templates/block.js" /* webpackChunkName: "component---src-templates-block-js" */),
  "component---src-pages-blocks-old-js": () => import("../src/pages/blocks-old.js" /* webpackChunkName: "component---src-pages-blocks-old-js" */),
  "component---src-pages-demo-js": () => import("../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-about-mdx": () => import("../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-blocks-mdx": () => import("../src/pages/blocks.mdx" /* webpackChunkName: "component---src-pages-blocks-mdx" */),
  "component---src-pages-docs-advanced-blocks-docs-generation-mdx": () => import("../src/pages/docs/advanced/blocks-docs-generation.mdx" /* webpackChunkName: "component---src-pages-docs-advanced-blocks-docs-generation-mdx" */),
  "component---src-pages-docs-advanced-index-mdx": () => import("../src/pages/docs/advanced/index.mdx" /* webpackChunkName: "component---src-pages-docs-advanced-index-mdx" */),
  "component---src-pages-docs-advanced-queries-mdx": () => import("../src/pages/docs/advanced/queries.mdx" /* webpackChunkName: "component---src-pages-docs-advanced-queries-mdx" */),
  "component---src-pages-docs-advanced-how-it-works-mdx": () => import("../src/pages/docs/advanced/how-it-works.mdx" /* webpackChunkName: "component---src-pages-docs-advanced-how-it-works-mdx" */),
  "component---src-pages-docs-controls-mdx": () => import("../src/pages/docs/controls.mdx" /* webpackChunkName: "component---src-pages-docs-controls-mdx" */),
  "component---src-pages-docs-advanced-transforms-mdx": () => import("../src/pages/docs/advanced/transforms.mdx" /* webpackChunkName: "component---src-pages-docs-advanced-transforms-mdx" */),
  "component---src-pages-docs-guides-index-mdx": () => import("../src/pages/docs/guides/index.mdx" /* webpackChunkName: "component---src-pages-docs-guides-index-mdx" */),
  "component---src-pages-docs-guides-write-a-custom-block-mdx": () => import("../src/pages/docs/guides/write-a-custom-block.mdx" /* webpackChunkName: "component---src-pages-docs-guides-write-a-custom-block-mdx" */),
  "component---src-pages-docs-index-mdx": () => import("../src/pages/docs/index.mdx" /* webpackChunkName: "component---src-pages-docs-index-mdx" */),
  "component---src-pages-getting-started-mdx": () => import("../src/pages/getting-started.mdx" /* webpackChunkName: "component---src-pages-getting-started-mdx" */),
  "component---src-pages-index-mdx": () => import("../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-prior-art-mdx": () => import("../src/pages/prior-art.mdx" /* webpackChunkName: "component---src-pages-prior-art-mdx" */),
  "component---src-pages-uses-mdx": () => import("../src/pages/uses.mdx" /* webpackChunkName: "component---src-pages-uses-mdx" */),
  "component---src-pages-vision-mdx": () => import("../src/pages/vision.mdx" /* webpackChunkName: "component---src-pages-vision-mdx" */)
}

